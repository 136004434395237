<template>
  <div class="activityContainer rtl">
    <van-nav-bar
      title="ەرەكشە قيمىلدار"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="isEmpty ? 'قازىرشە مازمۇن جوق' : ''"
      :error.sync="error"
      error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
      @load="onLoad"
      loading-text="ىزدەلۋدە"
    >
      <ul class="topic">
        <li
          v-for="(item, index) in activityList"
          :key="index"
          @click="detail(item.id, item.activity_code)"
        >
          <van-image
            lazy-load
            round
            :src="item.cover"
            fit="cover"
          />
          <p class="cate_name">{{ item.title }}</p>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { getAllActivity } from '@/api/Activity'
export default {
  name: 'List',
  components: {},
  props: {},
  data () {
    return {
      pages: {
        pagenum: 1,
        pagesize: 9,
        status: 1
      },
      activityList: [],
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      isEmpty: false
    }
  },
  methods: {
    detail (id, code) {
      this.$router.push({ name: 'activityDetail', params: { code, id } })
    },
    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await getAllActivity({
          ...this.pages
        })

        // 2. 把数据添加到 activityList 数组中
        const results = data.data
        this.activityList.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    }
  }
}
</script>
<style lang="less" scoped>
.activityContainer {
  padding-top: 92px;
  background-color: #fff;
  min-height: 100vh;
  // 专题
  .topic {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    background-color: #fff;
    li {
      display: block;
      width: 50%;
      margin: 0;
      margin-bottom: 10px;
      text-align: center;
      /deep/ .van-image {
        width: 94%;
        height: 200px;
        border-radius: 6px !important;
        object-fit: cover;
        margin-bottom: 14px;
        vertical-align: top;
      }
      .cate_name {
        font-size: 28px;
        text-align: center;
        padding: 0 20px;
        color: #3a3a3a;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
